<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <div class="card border-0 shadow-sm">
          <div class="card-body text-center">
            <div class="row mb-3">
              <div class="col-10 mx-auto">
                <h2>Give Feedback</h2>
                <h6>Tell us what you think. Please provide any feedback from your experience with Swandoola.</h6>
              </div>
            </div>
            <form @submit.prevent="addFeedback">
              <div class="row mb-3">
                <div class="col-8 mx-auto">
                  <textarea rows="8"
                          class="form-control"
                          v-model="form.comment"
                          placeholder="Your feedback..."
                          required>
                  </textarea>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col">
                  <input type="submit" class="btn btn-primary" value="Give Feedback"/>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        comment: "",
      }
    };
  },
  methods: {
    addFeedback(){
      this.$axios.post(process.env.VUE_APP_API_URL + "/feedback", this.form)
      .then(({ data }) => {
        this.$EventBus.$emit("alert", data);
        this.form.comment = " ";
      });
    }
  },
};
</script>

<style>
</style>